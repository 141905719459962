body {
  margin: 0px;
  background-color: #346395; /* brand-500 */
}

/*
Color Styles
https://www.figma.com/design/rA3HpXiwjwn1FSrD0nfiGD/strommen-byu-app?node-id=2317-93
*/
.app {
  /* Color Var Declarations */
  --brand-100: #73ACE5;
  --brand-300: #3D79B5;
  --brand-500: #346395;
  --brand-700: #2B4F78;
  --success-100: #73E5BC;
  --success-300: #3FB58A;
  --success-500: #349472;
  --success-700: #2A785B;
  --info-100: #73DCE5;
  --info-300: #3EABB5;
  --info-500: #348A94;
  --info-700: #297178;
  --warn-100: #E5AD73;
  --warn-300: #B57A3E;
  --warn-500: #946534;
  --warn-700: #785129;
  --error-100: #E57373;
  --error-300: #B53E3E;
  --error-500: #943534;
  --error-700: #782929;
  --neutral-100: #E6E6E6;
  --neutral-300: #B5B5B5;
  --neutral-500: #949494;
  --neutral-700: #787878;
  --black-and-white-white: #FFF;
  --black-and-white-black: #000;

  /* layout */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

/* Color Styles - Text */

.brand-100-text { color: var(--brand-100); }
.brand-300-text { color: var(--brand-300); }
.brand-500-text { color: var(--brand-500); }
.brand-700-text { color: var(--brand-700); }
.success-100-text { color: var(--success-100); }
.success-300-text { color: var(--success-300); }
.success-500-text { color: var(--success-500); }
.success-700-text { color: var(--success-700); }
.info-100-text { color: var(--info-100); }
.info-300-text { color: var(--info-300); }
.info-500-text { color: var(--info-500); }
.info-700-text { color: var(--info-700); }
.warn-100-text { color: var(--warn-100); }
.warn-300-text { color: var(--warn-300); }
.warn-500-text { color: var(--warn-500); }
.warn-700-text { color: var(--warn-700); }
.error-100-text { color: var(--error-100); }
.error-300-text { color: var(--error-300); }
.error-500-text { color: var(--error-500); }
.error-700-text { color: var(--error-700); }
.neutral-100-text { color: var(--neutral-100); }
.neutral-300-text { color: var(--neutral-300); }
.neutral-500-text { color: var(--neutral-500); }
.neutral-700-text { color: var(--neutral-700); }
.black-and-white-white-text { color: var(--black-and-white-white); }
.black-and-white-black-text { color: var(--black-and-white-black); }

/* Color Styles - Background */

.brand-100-bg { background: var(--brand-100); }
.brand-300-bg { background: var(--brand-300); }
.brand-500-bg { background: var(--brand-500); }
.brand-700-bg { background: var(--brand-700); }
.success-100-bg { background: var(--success-100); }
.success-300-bg { background: var(--success-300); }
.success-500-bg { background: var(--success-500); }
.success-700-bg { background: var(--success-700); }
.info-100-bg { background: var(--info-100); }
.info-300-bg { background: var(--info-300); }
.info-500-bg { background: var(--info-500); }
.info-700-bg { background: var(--info-700); }
.warn-100-bg { background: var(--warn-100); }
.warn-300-bg { background: var(--warn-300); }
.warn-500-bg { background: var(--warn-500); }
.warn-700-bg { background: var(--warn-700); }
.error-100-bg { background: var(--error-100); }
.error-300-bg { background: var(--error-300); }
.error-500-bg { background: var(--error-500); }
.error-700-bg { background: var(--error-700); }
.neutral-100-bg { background: var(--neutral-100); }
.neutral-300-bg { background: var(--neutral-300); }
.neutral-500-bg { background: var(--neutral-500); }
.neutral-700-bg { background: var(--neutral-700); }
.black-and-white-white-bg { background: var(--black-and-white-white); }
.black-and-white-black-bg { background: var(--black-and-white-black); }

/*
Typography Styles
https://www.figma.com/design/rA3HpXiwjwn1FSrD0nfiGD/strommen-byu-app?node-id=4311-96
*/
/* Typography Styles - Display */

.display-1 {
  font-family: "Carrois Gothic SC";
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

/* Typography Styles - Heading */

.heading-1 {
  font-family: "Carrois Gothic";
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.heading-2 {
  font-family: "Carrois Gothic";
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.heading-3 {
  font-family: "Carrois Gothic";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

/* Typography Styles - Label */

.label-1 {
  font-family: "Carrois Gothic";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: underline;
}

.label-2 {
  font-family: "Carrois Gothic";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.label-3 {
  font-family: "Carrois Gothic";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

/* Typography Styles - Paragraph */

.paragraph-1 {
  font-family: "Carrois Gothic";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.paragraph-2 {
  font-family: "Space Mono";
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

/* Component Styles */
/* Component Styles - Shared */

.shared-heading {
  /* layout */
  display: flex;
  padding: 10px 30px;
  justify-content: center;
  align-items: center;
  align-content: center;
  gap: 10px;
  flex-wrap: wrap;

  /* style */
  border-radius: 15px;
  background: var(--neutral-100);
}

/* Component Styles - Nav */
/* Component Styles - Nav - Top Menu*/

.top-menu {
  /* layout */
  display: flex;
  min-height: 100px;
  padding: 0px 20px;
  align-items: center;
  align-content: center;
  gap: 30px;
  align-self: stretch;
  flex-wrap: wrap;

  /* style */
  background: var(--brand-500, #346395);
}

.top-logo { /* FIXME - drift from figma*/
  /* behavior */
  pointer-events: none;

  /* layout */
  height: 50px;
  padding: 10px;

  /* style */
  border-radius: 25px;
  background: var(--black-and-white-white, #FFF);
}


.top-menu-name {
  /* behavior */
  pointer-events: none;

  /* style */
  color: var(--black-and-white-white, #FFF);
}

.link {
  color: var(--black-and-white-white, #FFF);
  text-decoration: none;
}

.link:hover {
  color: var(--black-and-white-black, #000);
  text-decoration: underline;
}

/* Component Styles - Nav - Content Body */

.nav-content-body {
  /* layout */
  display: flex;
  align-items: flex-start;
  gap: 5px;
  flex: 1 0 0;
  align-self: stretch;
  height: 100%;

  /* style */
  background: var(--neutral-700, #787878);
}

/* Component Styles - Nav - Side Rail */

.side-nav-rail {
  /* layout */
  display: flex;
  padding: 50px 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;

  /* style */
  background: var(--neutral-100, #E6E6E6);
}

.side-nav-item-list {
  /* layout */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;
}

.side-nav-item {
  max-width: 150px; /* FIXME Drift from figma */

  color: var(--black-and-white-black, #000);
}

.side-nav-item-name {
  color: var(--black-and-white-black, #000);
}

.side-nav-item-name:hover {
  color: var(--brand-500)
}

/* Component Styles - Nav - Page Content */

.page-content {
  /* FIXME: drift from figma */

  /* layout */
  display: flex;
  padding: 10px 20px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  flex: 1 0 0;

  /* style */
  border-radius: 25px;
  background: var(--black-and-white-white, #FFF);
}

.no-page-content {
  /* layout */
  display: flex;
  padding: 10px 20px;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;

  /* style */
  background: var(--neutral-700, #787878);
}

/* Component Styles - About Me */
/* Component Styles - About Me - Overview */

.about-me-full {
  /* layout */
  display: flex;
  padding: 10px 20px;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;

  /* style */
  background: var(--neutral-700, #787878);
}

.about-me-overview {
  /* layout */
  display: flex;
  padding: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  flex: 1 0 0;

  /* style */
  border-radius: 25px;
  background: var(--black-and-white-white, #FFF);
}

/* Component Styles - Fishers */
/* FIXME: drift from figma */

.fishers-list {
  /* layout */
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
}

/* Style for the ul */
.fishers-list-item {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  gap: 20px;
  justify-content: left;
}

/* Base style for links */
.fishers-link {
  text-decoration: none;
  font-size: 16px;
  color: var(--black-and-white-black);
  padding: 10px 20px;
  border: 2px solid var(--black-and-white-black);
  border-radius: 25px;
  transition: all 0.3s ease;
  background-color: var(--neutral-100);
}

/* Hover effect */
.fishers-link:hover {
  background-color: var(--brand-500);
  color: var(--black-and-white-white);
  border: 2px solid var(--black-and-white-black);
  box-shadow: 0 4px 10px var(--neutral-700);
}


/* Component Styles - Tic-tac-toe (patterns deprecated, do not copy) */

.board-row {
  min-width: 99px;
}

.board-row:after {
  clear: both;
  content: "";
  display: table;
}

.status {
  margin-bottom: 10px;
}

.square {
  background: #fff;
  border: 1px solid #999;
  float: left;
  font-size: 24px;
  font-weight: bold;
  line-height: 34px;
  height: 34px;
  margin-right: -1px;
  margin-top: -1px;
  padding: 0;
  text-align: center;
  width: 34px;
}

.square:focus {
  outline: none;
}

.kbd-navigation .square:focus {
  background: #ddd;
}

.game {
  display: flex;
  flex-direction: row;
}

.game-info {
  margin-left: 20px;
}
